import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter,  faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons'
import { useStaticQuery, graphql } from "gatsby";

const IndexPage = () =>{
  const { site } = useStaticQuery(graphql`
  {
    site {
      siteMetadata {
        title
        description
        personalData {
          email
          github
          linkedin
          name
          surname
          twitter
        }
      }
    }
  }
  `);
  return (
  <Layout>
    <SEO title="Home" />
    <h1 className="mb-0">
              {site.siteMetadata.personalData.name}<wbr />
              <span className="text-primary">
                {site.siteMetadata.personalData.surname}
              </span>
            </h1>
            <div className="subheading mb-5">
            {site.siteMetadata.description}

            </div>
            <p className="lead mb-5">
              Qua bio<br/>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
              Sed sollicitudin, augue sit amet ultricies posuere, 
              justo neque vehicula lacus, malesuada egestas justo purus 
              ut justo. Praesent viverra lacinia elit, ac accumsan ipsum 
              maximus ac. Suspendisse feugiat tempor sem, et elementum
               nisl viverra ac. Donec mattis nibh vel turpis efficitur, 
               vel viverra magna blandit. Phasellus convallis libero id 
               ligula lobortis dictum. Integer efficitur nulla ex, eget 
               fringilla urna hendrerit a. Nam at augue iaculis lorem 
               blandit mollis sit amet ac erat. Nam a feugiat sapien. 
               Class aptent taciti sociosqu ad litora torquent per conubia 
               nostra, per inceptos himenaeos. Fusce id quam turpis. 
               Maecenas vestibulum venenatis scelerisque. Maecenas finibus 
               pellentesque scelerisque. Donec lobortis accumsan massa.
            </p>
            <div className="social-icons">
              <a 
                className="social-icon" 
                href={site.siteMetadata.personalData.github} 
                rel="noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon icon={faGithub} />
              </a>
              <a 
              className="social-icon" 
              href={site.siteMetadata.personalData.linkedin} 
              target="_blank"
              rel="noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
              <a 
                className="social-icon" 
                href={site.siteMetadata.personalData.twitter} 
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
            </div>
  </Layout>
);
  };
export default IndexPage
